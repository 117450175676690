import { List, ListSubheader, Link, ListItemButton, ListItemIcon, ListItemText, Divider } from "@mui/material"
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual'
import { useSelector } from "react-redux"
import { selectSelectedItemMenu } from "../../../store/selectors"
import { useDispatch } from "react-redux"
import { ACT_MENU_SELECT_ITEM } from "../../../store/actions"
import { useNavigate } from "react-router-dom"
import LocalMoviesIcon from "@mui/icons-material/LocalMovies"
import "./Menu.css"

function Menu({ setMobileOpen }: any) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const selectedItem = useSelector(selectSelectedItemMenu())
    const menuItemsList = [
        [ "Affichettes", "/flyers", <PhotoSizeSelectActualIcon fontSize="small" /> ]
    ]

    const handleMenuItemClick = (selectedItem: any) => {
        dispatch(ACT_MENU_SELECT_ITEM(selectedItem))
        if(setMobileOpen !== undefined) setMobileOpen(false)
        navigate(selectedItem)
    }

    return (
        <List id="listMenu" 
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={<ListSubheader component="div" id="nested-list-subheader">Modules</ListSubheader>}>
            { menuItemsList.map((x: any, index: any) => (
                <Link key={index} style={{ textDecoration: 'none' }} onClick={() => handleMenuItemClick(x[1])}>
                    <ListItemButton selected={selectedItem === x[1]}>
                        <ListItemIcon>
                            {x[2]}
                        </ListItemIcon>
                        <ListItemText primary={x[0]} />
                    </ListItemButton>
                </Link>
            )) }
        </List>
    )
}

export default Menu
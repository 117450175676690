import { Typography, Box, Chip, Card, CardMedia, CardContent, CardActions, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell } from "@mui/material"
import { useEffect, useState } from "react"
import Loader from "../../components/feedback/Loader"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"
import FlyerButton from "../../components/buttons/FlyerButton"
import FilterAltIcon from "@mui/icons-material/FilterAlt"
import SortIcon from "@mui/icons-material/Sort"
import api from "../../services/api"
import "./FlyersPage.css"

const FlyersPage = () => {
    const [ data, setData ] = useState<any>({})
    const [ isDataLoading, setDataLoading ] = useState(true)
    const [ showTheater, setShowTheater ] = useState<string | undefined>(localStorage.getItem('FlyersTheater') !== null ? (localStorage.getItem('FlyersTheater') as string|undefined) : '')
    const [ orderBy, setOrderBy ] = useState<string | undefined>(localStorage.getItem('FlyersOrder') !== null ? (localStorage.getItem('FlyersOrder') as string|undefined) : 'movie')

    const handleShowTheaterChange = (event: SelectChangeEvent) => {
        localStorage.setItem('FlyersTheater', event.target.value)
        setShowTheater(event.target.value)
    }

    const handleOrderByChange = (event: SelectChangeEvent) => {
        localStorage.setItem('FlyersOrder', event.target.value)
        setOrderBy(event.target.value)
    }

    useEffect(() => {
        document.title = "Cinapps - Affichettes"
        
        async function fetchFlyers() {
            setDataLoading(true)
            try {
                const response = await api.get(`/flyers?showTheater=${showTheater}&orderBy=${orderBy}`)
                .catch((error) => {
                    return error.response
                })
                if(response.status === 200){
                    setData(response.data)
                }
            } catch(err) {
                console.log(err)
            } finally {
                setDataLoading(false)
            }
        }

        fetchFlyers()
    }, [ setData, setDataLoading, showTheater, orderBy ])

    return (
        <>
            <Typography variant="h4" component="h2">Affichettes</Typography>
            <Box sx={{ display:'flex', alignItems:'center', flexWrap:'wrap', mb: 2 }}>
                <Typography variant="h5" sx={{ mr: 2 }}>
                    <FilterAltIcon />
                </Typography>
                <FormControl variant="standard" sx={{ minWidth:120, mr: 2 }}>
                    <InputLabel>Cinémas</InputLabel>
                    <Select label="Cinémas"
                        value={showTheater} 
                        onChange={handleShowTheaterChange}>
                        <MenuItem value="">Tous</MenuItem>
                        <MenuItem value="Cinematographe">Tramelan</MenuItem>
                        <MenuItem value="Royal">Tavannes</MenuItem>
                    </Select>
                </FormControl>
                <div style={{ flexGrow: 1 }}></div>
                <Typography variant="h5" sx={{ ml: 2, mr: 2 }}>
                    <SortIcon />
                </Typography>
                <FormControl variant="standard" sx={{ minWidth:120 }}>
                    <InputLabel>Trier par</InputLabel>
                    <Select label="Trier par"
                        value={orderBy} 
                        onChange={handleOrderByChange}>
                        <MenuItem value="movie">Film</MenuItem>
                        <MenuItem value="date">Date</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {isDataLoading ? (
                <Loader />
            ) : data !== undefined ? (
                <Grid2 container spacing={2}>
                {
                    data.map((x: any, index: any) => (
                        <Grid2 xs={12} lg={6} xl={4} xxl={3} xxxl={2} key={`Grid2-${index}-${x.theater.name}-${x.movie.id}`}>
                            <Card elevation={3} sx={{ display: 'flex' }}>
                                <CardMedia component="img"
                                    sx={{ maxWidth: 151 }}
                                    image={ x.images[0].url }
                                    alt={x.movie.title} />
                                <CardContent sx={{ flexGrow: 1, display:'flex', flexDirection:'column' }}>
                                    <div style={{ flexGrow:1 }}>
                                        <Typography component="div" variant="h5" style={{ wordWrap: "break-word" }}>{x.movie.title}</Typography>
                                        <Typography variant="subtitle1" color="text.secondary" component="div">{x.theater.name}</Typography>
                                        { x.shows.length > 0 && (
                                            <Chip label={`${new Date(x.shows[0].date).toLocaleDateString("fr-CH", { hour: "2-digit", minute: "2-digit" })} | ${x.shows[0].version}`} color="default" size="small" sx={{ mt:1 }} />
                                        )} 
                                        { x.shows.length > 1 && (
                                            <Chip label={`+ ${x.shows.length-1}`} color="secondary" size="small" sx={{ ml:1, mt:1 }} />
                                        )}
                                    </div>
                                    <CardActions sx={{ p:0 }}>
                                        <FlyerButton imageList={x.images} showTheater={x.theater.name} movieId={x.movie.id} />
                                    </CardActions>
                                </CardContent>
                            </Card>
                        </Grid2>
                    ))
                }
                </Grid2>
                ) : (
                    null
                )
            }
        </>
    )
}

export default FlyersPage
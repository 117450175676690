import axios from "axios"

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
})

api.defaults.headers.common["Accept"] = "application/json"
api.defaults.headers.post["Content-Type"] = "application/json"

api.interceptors.request.use(
    (config) => {
        const localUser = localStorage.getItem("user")
        if(localUser) {
            const jsonUser = JSON.parse(localUser)
            if(jsonUser.token) {
                config.headers["Authorization"] = `Bearer ${jsonUser.token}`
            }
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

export default api
import { Button, ButtonGroup, Menu, MenuItem } from "@mui/material"
import { useState } from "react"
import Loader from '../feedback/Loader'
import { saveAs } from "file-saver"
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import api from "../../services/api"
import SettingsIcon from '@mui/icons-material/Settings'
import { useNavigate } from "react-router-dom"

type Props = {
    imageList: any,
    showTheater: string,
    movieId: any
}

function FlyerButton({ imageList, showTheater, movieId }: Props) {
    const [ isDataLoading, setDataLoading ] = useState(false)
    const [ isDisabled, setDisabled ] = useState(false)
    const [anchorImageMenu, setAnchorImageMenu] = useState<HTMLElement | null>(null)
    const imageMenuOpen = Boolean(anchorImageMenu)
    const navigation = useNavigate()
    
    const generateFlyer = (imgURL: string, imgName: string) => {
        async function fetchFlyers() {
            setDataLoading(true)
            setDisabled(true)
            try {
                const response = await api.get(imgURL, {
                    responseType: 'blob'
                }).catch((error) => error.response)
                if(response.status === 200){
                    const imageObjectURL = URL.createObjectURL(response.data)
                    saveAs(imageObjectURL, imgName)
                }
                /*const imageBlob = await response.blob()
                const imageObjectURL = URL.createObjectURL(imageBlob)
                saveAs(imageObjectURL, imgName)*/
            } catch(err) {
                console.log(err)
            } finally {
                setDisabled(false)
                setDataLoading(false)
            }
        }
        setAnchorImageMenu(null)
        fetchFlyers()
    }

    const handleImageItemClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorImageMenu(event.currentTarget);
    }

    const handleImageMenuClose = () => {
        setAnchorImageMenu(null);
    }

    const handleSettingsButton = () => {
        navigation(`/flyers/edit/${movieId}`)
    }

    return (
        <>
            <ButtonGroup variant="contained">
                <Button onClick={handleImageItemClick} 
                    disabled={isDisabled}
                    color="primary" 
                    size="small" 
                    variant="contained" 
                    endIcon={<ArrowDropDownIcon />}
                    aria-controls={imageMenuOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={imageMenuOpen ? 'true' : undefined}>Télécharger { isDataLoading && ( <Loader /> ) }</Button>
                <Button onClick={() => handleSettingsButton()}
                    color="warning" 
                    size="small"
                    variant="contained"><SettingsIcon fontSize="small" /></Button>
            </ButtonGroup>
            <Menu id="basic-menu"
                anchorEl={anchorImageMenu}
                open={imageMenuOpen}
                onClose={handleImageMenuClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                {
                    imageList.map((imgPub: any, indexFlyer: any) => indexFlyer === 0 ? null : (
                        <MenuItem key={`MenuItem-${indexFlyer}-${showTheater}-${movieId}`} onClick={() => generateFlyer(imgPub.url, imgPub.description)}>
                            { imgPub.description }
                        </MenuItem>
                    ))
                }
            </Menu>
        </>
    )
}

export default FlyerButton
export const selectSelectedItemMenu = () => {
    return (state: any) => state.menu.selectedItem
}

export const selectConnectedUser = () => {
    return (state: any) => state.user.profile
}

export const selectMessageAlert = () => {
    return (state: any) => state.message.alert
}
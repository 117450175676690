import { Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import Loader from "../components/feedback/Loader"
import api from "../services/api"

const HtmlPage = () => {
    const location = useLocation()
    const [dataLoading, setDataLoading] = useState(false)
    const [pageData, setPageData] = useState<any>({})

    useEffect(() => {
        async function fetchPage() {
            setDataLoading(true)
            try {
                const pathName = location.pathname.replace('/page/', '')
                const response = await api.get(`/pages/${pathName}`).catch((error) => { return error.response })
                if(response.status === 200){
                    const resultat = response.data
                    document.title = `Cinapps - ${resultat.title}` 
                    setPageData(resultat)
                }
            } catch(err) {
                console.log(err)
            } finally {
                setDataLoading(false)
            }
        }
        
        fetchPage()
    }, [location.pathname])

    return (
        <div>
        {
            dataLoading ? (
                <Loader />
            ) : pageData !== undefined ? (
                <div>
                    <Typography variant="h4" mb={2}>{pageData.title}</Typography>
                    <Typography component="div" paragraph>
                        <div dangerouslySetInnerHTML={{__html: pageData.body}} />
                    </Typography>
                </div>
            ) : (
                null
            )
        }
        </div>
    )
}

export default HtmlPage
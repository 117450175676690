import { Typography, Divider, Link } from "@mui/material"
import "./Footer.css"

function Footer() {
    return (
        <>
            <Divider id="loginDivider" />
            <Typography id="typoFooter" variant="overline">© 2023-2024 Kévin Carnal<br />Cinapps v2.1</Typography>
        </>
    )
}

export default Footer
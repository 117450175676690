import { Box, Button, ButtonGroup, Hidden, Tab, Tabs, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useNavigate, useNavigation, useParams } from "react-router-dom"
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import api from "../../services/api"
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import "./FlyersEditPage.css"
import { theme } from "../../styles/theme"
import { group } from "console"
import MessageAlert from "../../models/MessageAlert"
import { useDispatch } from "react-redux"
import { ACT_MESSAGE_ALERT } from "../../store/actions"

const FlyersEditPage = () => {
    const params = useParams()
    const navigation = useNavigate()
    const [data, setData] = useState<any>(undefined)
    const [dataLoading, setDataLoading] = useState<boolean>(false)
    const [tabValue, setTabValue] = useState<string>("1")
    const [selectedPoster, setSelectedPoster] = useState<number>(0)
    const [selectedBackdrop, setSelectedBackdrop] = useState<number>(0)
    const dispatch = useDispatch()

    useEffect(() => {
        document.title = "Cinapps - Affichette"
        fetchData()
    }, [])

    const fetchData = async() => {
        try {
            setDataLoading(true)

            const response = await api.get(`/flyers/${params.movieId}`)
            .catch((error) => {
                return error.response
            })

            if(response.status === 200) {
                document.title = `Cinapps - Affichette ${response.data.movie.title}`
                setData(response.data)
                setSelectedPoster(response.data.movie.posterId)
                setSelectedBackdrop(response.data.movie.backdropId)
            }
        } catch(err) {
            console.log(err)
        } finally {
            setDataLoading(false)
        }
    }

    const handleSaveButton = async() => {
        const newMessage: MessageAlert = { open: true, message: "", severity: undefined }

        try {
            setDataLoading(true)

            const response = await api.put(`/flyers/${params.movieId}`, {
                posterId: selectedPoster,
                backdropId: selectedBackdrop
            })
            .catch((error) => {
                return error.response
            })

            if(response.status === 204) {
                newMessage.message = "Affichette mise à jour avec succès !"
                newMessage.severity = "success"
                navigation(-1)
            } else {
                newMessage.message = response.data
                newMessage.severity = "error"
            }
        } catch(err) {
            console.log(err)
        } finally {
            dispatch(ACT_MESSAGE_ALERT(newMessage as any))
            setDataLoading(false)
        }
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    }

    const handleCancelButton = () => {
        navigation(-1)
    }

    return (
        <>
            { data ? (
            <>
                <Typography variant="h4" component="h2">Affichette {data.movie.title}</Typography>

                <Box>
                    <Tabs value={tabValue} onChange={handleTabChange}>
                        <Tab label="Posters" value="1" />
                        <Tab label="Captures" value="2" />
                    </Tabs>
                </Box>

                <div className="divGallery" style={{ padding: 16, flexGrow: 1, flexShrink: 1, flexBasis: "auto", overflowX: "hidden", overflowY: "auto", minHeight: "100px", display: tabValue === "1" ? "block" : "none" }}>
                    <Grid container spacing={2}>
                        { data.posters.map((poster: any, index: number) => (
                            <Grid xs={6} sm={4} md={3} lg={2} xl={1}
                                sx={[
                                    { '&:hover': { backgroundColor: theme.palette.success.light, cursor: "pointer" } },
                                    poster.id === selectedPoster && { backgroundColor: theme.palette.success.main }
                                ]}>
                                <img src={process.env.REACT_APP_API_BASE_URL + poster.url} onClick={() => setSelectedPoster(poster.id)} />
                            </Grid>
                        )) }
                    </Grid>
                </div>

                <div className="divGallery" style={{ padding: 16, flexGrow: 1, flexShrink: 1, flexBasis: "auto", overflowX: "hidden", overflowY: "auto", minHeight: "100px", display: tabValue === "2" ? "block" : "none" }}>
                    <Grid container spacing={2}>
                        { data.backdrops.map((backdrop: any, index: number) => (
                            <Grid xs={6} sm={4} md={3} lg={2} xl={1} 
                                sx={[
                                    { '&:hover': { backgroundColor: theme.palette.success.light, cursor: "pointer" } },
                                    backdrop.id === selectedBackdrop && { backgroundColor: theme.palette.success.main }
                                ]}>
                                <img src={process.env.REACT_APP_API_BASE_URL + backdrop.url} onClick={() => setSelectedBackdrop(backdrop.id)} />
                            </Grid>
                        )) }
                    </Grid>
                </div>

                <ButtonGroup variant="contained" disableElevation>
                    <Button onClick={() => handleCancelButton()} 
                        disabled={dataLoading}
                        color="error"
                        startIcon={<CancelIcon />}>Annuler</Button>
                    <Button onClick={() => handleSaveButton()} 
                        disabled={dataLoading}
                        color="success"
                        startIcon={<CheckCircleIcon />}>Enregistrer</Button>
                </ButtonGroup>
            </>
            ) : (null)}
        </>
    )
}

export default FlyersEditPage